import React, {FunctionComponent} from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from "./pages/home";

const AppRoutes: FunctionComponent = () => (
  <Routes>
    <Route path="*" element={<Home/>}/>
  </Routes>
);

export default AppRoutes;
