import React, {useState} from 'react';
import background from '../../assets/background.png';
import styled from "styled-components";
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import {Button, Input, message, Space} from "antd";
import axios from "axios";

const Container = styled.div`
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    text-align: center;
    color: #a4a1a1;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.22), rgba(201, 201, 201, 0.53)), url(${background});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: rgba(255, 255, 255, 0.5);
`;

const Logo = styled.div`
    position: fixed;
    color: #2c2c2c;
    top: 2%;
    left: 2%;
    font-size: 1.5em;
`;

const ComingSoonContainer = styled.div`
    font-size: 2.5em;
    font-weight: 300;
    padding-top: 8%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    //text-transform: uppercase;
`;

const ComingSoon = styled.div`
    flex-direction: column;
    align-items: center;
`;

const NotifyForm = styled.div`
    margin-top: 35px;
    font-size: 0.5em;
    font-weight: 100;
    flex-direction: column;
    align-items: center;
    color: #2c2c2c;
    width: 400px;
`;

const Home = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    try {
      await axios.post('https://api.geojson.ai/v1/waitlist/member/add', {email});
      setEmail('');
      message.success('Thank you! We will email you as soon as the site is ready!');
    } catch (error) {
      message.error('There was an error submitting your request.');
    }
  };

  return (
    <Container>
      <Logo>
        geojson.ai
      </Logo>
      <ComingSoonContainer>
        <ComingSoon>
          The website is coming soon!
        </ComingSoon>

        <div style={{marginTop: 25}}>
          <FlipClockCountdown
            to={new Date(2024, 8, 30).getTime()}
            digitBlockStyle={{width: 40, height: 60, fontSize: 30}}
          />
        </div>

        <NotifyForm>
          <div style={{paddingBottom: 20}}>
            Notify me when it's ready
          </div>
          <Space.Compact style={{width: '100%'}}>
            <Input
              size={'large'}
              placeholder="Enter your email to be notified..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <Button type="primary" onClick={handleSubmit} size={'large'}>Submit</Button>
          </Space.Compact>
        </NotifyForm>

      </ComingSoonContainer>
    </Container>
  );
}

export default Home;
